import { Button } from '@mui/material'
import { Box } from '@mui/system'

interface ButtonProps {
  text: string
  fullWidth?: boolean
  handleClick?: () => void
  isDisabled?: boolean
  customTopMargin?: string
}
export const ButtonPrimary = ({ text, handleClick, fullWidth, isDisabled, customTopMargin }: ButtonProps) => {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'bottom',
        maxWidth: fullWidth ? '100%' : '300px',
        marginBottom: '20px',
        marginTop: customTopMargin!! ? customTopMargin : '10px',
      }}
      mx="auto"
    >
      <Button
        variant="contained"
        onClick={handleClick}
        disabled={isDisabled}
        sx={{
          '&.Mui-disabled': {
            backgroundColor: 'grey',
            color: '#c0c0c0',
          },
        }}
      >
        {text}
      </Button>
    </Box>
  )
}
