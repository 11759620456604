import { useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { Toaster, ToastBar, toast } from 'react-hot-toast'

export const NotificationToaster = () => {
  const [isMounted, setIsMounted] = useState(false)

  // To prevent hydratation error
  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) {
    return null
  }

  return (
    <Toaster
      position="bottom-right"
      reverseOrder
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: '',
        duration: Infinity,
        style: {
          fontSize: '1.1rem',
          background: '#3b0505',
          border: '1px solid #ec2424',
          color: 'white',
        },
        // Default options for specific types
        // success: {
        //   duration: Infinity,
        //   theme: {
        //     primary: 'green',
        //     secondary: 'black',
        //   },
        // },
      }}
    >
      {t => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' &&   <CloseIcon sx={{ alignSelf: 'flex-start' }} fontSize="small" onClick={() => toast.dismiss(t.id)}/> }
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}

