import { Alert } from '@mui/material'
import { Box } from '@mui/system'
import { AxiosError } from 'axios'
import { FallbackProps } from 'react-error-boundary'
import { CUSTOM_ERROR_CODES } from '@/types/errors'
import { ButtonPrimary } from './ButtonPrimary'

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  let errorMessage = ''
  let buttonName = ''
  let displayButton = false

  if (error instanceof AxiosError) {
    // tslint:disable-next-line:no-unsafe-optional-chaining
    errorMessage = `ERROR: ${error.response?.data.message}`
  } else {
    errorMessage = `ERROR: ${error.message}`
  }

  if (errorMessage === CUSTOM_ERROR_CODES.SLOT_EXPIRED || errorMessage === CUSTOM_ERROR_CODES.SLOT_EXPIRED_CZ) {
    displayButton = true
    buttonName = errorMessage === CUSTOM_ERROR_CODES.SLOT_EXPIRED ? 'SELECT NEW SLOT' :'VYBRAT NOVÝ TERMÍN'
  }
  return (
    <Box>
      <Alert severity="error">{errorMessage}</Alert>
      {displayButton && <ButtonPrimary text={buttonName} handleClick={() => resetErrorBoundary()} customTopMargin='25px'/>}
    </Box>
  )
}
