import { createTheme } from '@mui/material/styles'

interface PaletteColor {
  light?: string
  main: string
  dark?: string
  contrastText?: string
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxs: true // adds custom breakpoint
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 375,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#bd1213',
    },
    secondary: {
      main: '#000',
      light: '#212529',
    },
    success: {
      main: '#00af87',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#ffffff',
      secondary: '#aeaeae',
    },
  },
  typography: {
    fontFamily: ['AbelRegular', 'ChangaOne'].join(','),
    htmlFontSize: 10,
    h5: {
      fontSize: '1.5rem',
      textAlign: 'left',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1,
    },
    button: {
      fontFamily: 'ChangaOne',
      fontWeight: '500',
    },
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage:
            'url(https://thrillparkprague.com/resources/css/img/tp_web2018_cta_bg_wood.jpg)',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 30,
        },
        root: {
          wordWrap: 'break-word',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          color: '#ffffff',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#ffffff',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            backgroundColor: 'black', // change this to the desired color
          },
          '& .MuiInputBase-root:hover': {
            backgroundColor: 'black', // change this to the desired color
          },
          '& .MuiInputBase-root:focus': {
            backgroundColor: 'black', // change this to the desired color
          },
          '& .MuiFilledInput-root:active': {
            backgroundColor: 'black', // change this to the desired color
          },
          '&.noMarginBottom': {
            marginBottom: 0, // Remove marginBottom for the TextField
          },
          marginBottom: '16px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&': {
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
            borderRadius: '50%',
            width: '100%',
            height: '100%',
            border: '1px solid rgba(255,255,255,.15) !important',
            borderTopRightRadius: '50% !important',
            borderBottomRightRadius: '50% !important',
            borderTopLeftRadius: '50% !important',
            borderBottomLeftRadius: '50% !important',
            maxWidth: '75px',
          },
          '&: hover': {
            backgroundColor: theme.palette.secondary.main,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
            border: '2px solid !important',
            borderColor: theme.palette.secondary.main,
            '&: hover': {
              backgroundColor: theme.palette.secondary.main,
            },
          },
        }),
      },
    },
  },
})

theme.typography.h1 = {
  color: 'white',
  fontFamily: 'ChangaOne',
  fontSize: '3rem',
  fontWeight: 'normal',
  lineHeight: 1,
  textAlign: 'center',
  [theme.breakpoints.up('xxs')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('xs')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '3rem',
  },
}

theme.typography.h2 = {
  color: 'white',
  fontFamily: 'ChangaOne',
  fontSize: '1.5rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.2rem',
  },
}

theme.typography.h3 = {
  color: 'white',
  fontFamily: 'AbelRegular',
  fontSize: '1.2rem',
  lineHeight: 1,
  fontWeight: 'bold',
  [theme.breakpoints.up('xs')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2rem',
  },
}

theme.typography.h4 = {
  color: 'white',
  fontFamily: 'AbelRegular',
  fontSize: '1rem',
  fontWeight: 300,
  lineHeight: 1,
  [theme.breakpoints.up('xs')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.3rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.8rem',
  },
}

theme.typography.subtitle1 = {
  // color: 'white',
  // fontFamily: 'AbelRegular',
  fontSize: '1rem',
  fontWeight: 300,
  lineHeight: 1.5,
  [theme.breakpoints.up('xs')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.6rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.8rem',
  },
}

theme.typography.body2 = {

  [theme.breakpoints.up('xs')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
}

theme.typography.h6 = {
  color: 'white',
  fontFamily: 'AbelRegular',
  fontSize: '1.3rem',
  fontWeight: 300,
  lineHeight: 1,
  [theme.breakpoints.up('xs')]: {
    fontSize: '1.4rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.8rem',
  },
}

export default theme
